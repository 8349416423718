<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    @click:outside="onClose"
  >
    <template #activator="{ on: dialog }">
      <v-btn
        color="secondary"
        block
        @click="onShowDialog"
        v-on="{ ...dialog }"
        data-test="activateButton"
      >
        {{ currentNote ? "Update Notes" : "Add Notes" }}
      </v-btn>
    </template>
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark>
          <v-toolbar-title data-test="notesToolbar">Add Notes</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="closeButton">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" md="8">
              <v-form ref="form">
                <v-textarea
                  label="Notes"
                  prepend-icon="mdi-card-text"
                  v-model.lazy="textNote"
                  rows="1"
                  auto-grow
                  data-test="textNotes"
                />
              </v-form>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                block
                :disabled="!textNote"
                color="secondary"
                @click="saveHandler"
                data-test="saveButton"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AddNotesModal",
  props: {
    currentNote: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      dialog: false,
      textNote: null,
    };
  },
  methods: {
    onShowDialog() {
      this.textNote = this.currentNote ? this.currentNote : null;
    },
    saveHandler() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("saveNotes", this.textNote);
      this.onClose();
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
